import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ItemCard, ItemCardUtils, ShoppingCartProps } from 'react-style-guide';
import { DefaultThumbnailSize, Thumbnail2d, ThumbnailTypes } from 'roblox-thumbnails';
import { BadgeSizes, VerifiedBadgeIconContainer } from 'roblox-badges';
import classNames from 'classnames';
import { WithTranslationsProps, withTranslations } from 'react-utilities';
import ShimmerContainer from '../../shimmerContainer/ShimmerContainer';
import {
  Category,
  Creator,
  CurrencyType,
  Layout,
  Library,
  Topic,
  Subcategory,
  TopUp,
  AssetsCollection
} from '../../../constants/types';
import CatalogBreadcrumbs from '../../catalogBreadcrumbs/CatalogBreadcrumbs';
import useShoppingCart from '../../../../shoppingCart/hooks/useShoppingCart';
import TopUpGiftCardBackDec from '../../../../../../images/topUp/TopUpGiftCardBackDec.png';
import TopUpGiftCardFrontDec from '../../../../../../images/topUp/TopUpGiftCardFrontDec.png';
import TopUpChest from '../../../../../../images/topUp/TopUpChest.png';
import { CatalogQuery } from '../../../hooks/catalogQuery/catalogQuery.types';
import { SearchOptionsData } from '../../../hooks/searchOptions/searchOptions.types';
import TopicsCarousel from './TopicsCarousel';
import { TAssetItemDetails } from '../../../../itemDetailsInfo/constants/types';
import catalogConstants from '../../../constants/catalogConstants';
import { translationConfig } from '../../../translation.config';
import useOrderedItems from '../useOrderedItems';

export type ItemsContainerProps = {
  searchOptions: SearchOptionsData;
  layout: Layout;
  topicBasedBrowsingEnabledForCategory?: boolean;
  topUp: TopUp;
  catalogQuery: CatalogQuery;
  isKeywordCensored: boolean;
  fetchNextFromInfiniteScroll: () => void;
  searchResultDict: AssetsCollection | null;
  searchResultList: string[] | null;
  isInfiniteScrollWebEnabled: boolean;
  library: Library;
  topics: Topic[];
  getCategoryOption: (category?: Category) => Category | undefined;
  setSubcategory: (subcategory: Subcategory | null) => void;
  setCreator: (creator?: Creator | null) => void;
  setCurrencyType: (currencyType: CurrencyType | null | undefined) => void;
  clearKeyword: () => void;
  onTopUpClear: () => void;
  setSelectedTopics: (selectedTopics: Topic[]) => void;
  onClearFilters: (keepKeyword: boolean) => void;
  clearTopics: () => void;
};

function ItemsContainer(props: ItemsContainerProps & WithTranslationsProps): JSX.Element {
  const {
    layout,
    topicBasedBrowsingEnabledForCategory,
    topUp,
    fetchNextFromInfiniteScroll,
    searchResultDict,
    searchResultList,
    isInfiniteScrollWebEnabled,
    catalogQuery,
    isKeywordCensored,
    searchOptions,
    library,
    topics,
    getCategoryOption,
    setSubcategory,
    setCreator,
    setCurrencyType,
    clearKeyword,
    onTopUpClear,
    setSelectedTopics,
    onClearFilters,
    clearTopics,
    translate
  } = props;

  const { isSearchOptionsLoaded } = searchOptions;

  const { isItemInCart, addItemToCart, removeItemFromCart } = useShoppingCart();

  // Helper to check if infinite scroll is enabled
  const isInfiniteScrollEnabled =
    (library.initialized && library.isPhone) || isInfiniteScrollWebEnabled;

  // Helper to determine the number of items to display
  const getNumberItemToDisplay = useCallback(() => {
    if (
      catalogQuery.category &&
      catalogConstants.expandedCategoryList.includes(catalogQuery.category.category)
    ) {
      return catalogConstants.numberOfSearchItemsExpanded;
    }
    if (isInfiniteScrollEnabled && isInfiniteScrollWebEnabled) {
      return catalogConstants.numberOfSearchItemsExpanded;
    }
    if (library.isFullScreen) {
      return catalogConstants.numberOfSearchItemsForFullScreen;
    }
    return searchResultList === null ? 0 : searchResultList.length;
  }, [
    catalogQuery.category,
    isInfiniteScrollEnabled,
    isInfiniteScrollWebEnabled,
    library.isFullScreen,
    searchResultList
  ]);

  const [numberOfItemsToDisplay, setNumberOfItemsToDisplay] = useState(0);
  useEffect(() => {
    setNumberOfItemsToDisplay(getNumberItemToDisplay());
  }, [getNumberItemToDisplay]);

  const isBreadcrumbsAvailable = library.initialized && !library.isPhone;

  const showReturnToTop =
    searchResultList !== null &&
    searchResultList.length > catalogConstants.numberOfSearchItemsExpanded;

  const returnToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const showTopUpComponent = !!(
    topUp.featureEnabled &&
    topUp.showTopUp &&
    topUp.robuxMinThreshold !== undefined &&
    topUp.robuxMaxThreshold !== undefined &&
    topUp.currentUserBalance !== undefined &&
    topUp.currentUserBalance > topUp.robuxMinThreshold &&
    topUp.currentUserBalance <= topUp.robuxMaxThreshold &&
    catalogQuery.category &&
    topUp.displayCategories?.includes(catalogQuery.category.categoryId) &&
    catalogQuery.keyword === undefined &&
    !catalogQuery.topics?.length &&
    topUp.headerText
  );

  const { orderedItems, showNonInfiniteItems, showShimmer } = useOrderedItems(
    layout,
    library,
    searchResultDict,
    searchResultList,
    isInfiniteScrollEnabled,
    isInfiniteScrollWebEnabled,
    numberOfItemsToDisplay
  );

  const loader = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        const first = entries[0];
        if (first.isIntersecting) {
          fetchNextFromInfiniteScroll();
        }
      },
      { threshold: 1 }
    );

    const currentLoader = loader.current;

    if (currentLoader) {
      observer.observe(currentLoader);
    }

    return () => {
      if (currentLoader) {
        observer.unobserve(currentLoader);
      }
    };
  }, [loader, fetchNextFromInfiniteScroll]);

  const closeTopUp = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onTopUpClear();
    e.stopPropagation();
  };

  return (
    <div id='react-items-container' className='catalog-results'>
      {!isSearchOptionsLoaded && (
        <h2 className='featured-items-heading'>
          <span className='shimmer-lines'>
            <span className='placeholder shimmer-line' />
          </span>
        </h2>
      )}
      {isBreadcrumbsAvailable && (
        <div>
          <CatalogBreadcrumbs
            catalogQuery={catalogQuery}
            searchOptions={searchOptions}
            getCategoryOption={getCategoryOption}
            setSubcategory={setSubcategory}
            setCreator={setCreator}
            setCurrencyType={setCurrencyType}
            clearKeyword={clearKeyword}
            isKeywordCensored={isKeywordCensored}
          />
        </div>
      )}
      <TopicsCarousel
        topicBasedBrowsingEnabledForCategory={topicBasedBrowsingEnabledForCategory}
        catalogQuery={catalogQuery}
        topics={topics}
        setSelectedTopics={setSelectedTopics}
        onClearFilters={onClearFilters}
        clearTopics={clearTopics}
      />
      {/* Top Up Feature */}
      {showTopUpComponent && (
        <div className='top-up-container'>
          {topUp.giftCard ? (
            <div
              className='gift-card'
              onClick={() => {
                window.location.href = topUp.buttonUrl;
              }}
              onKeyPress={e => {
                if (e.key === 'Enter' || e.key === 'Space') {
                  window.location.href = topUp.buttonUrl;
                }
              }}
              tabIndex={0}
              role='link'>
              <div className='top-up-background' />
              <img src={TopUpGiftCardBackDec} className='top-up-gift-card-back' alt='' />
              <img src={TopUpGiftCardFrontDec} className='top-up-gift-card-front' alt='' />
              <div className='top-up-body'>
                <h1 className='top-up-header'>{translate(topUp.headerText)}</h1>
                <div className='top-up-message'>{translate(topUp.messageText)}</div>
                <a className='top-up-button btn-cta-md' href={topUp.buttonUrl} target='_self'>
                  {translate(topUp.buttonText)}
                </a>
              </div>
              <button
                type='button'
                aria-label='Close top up'
                className='top-up-close-background transparent-button'
                onClick={closeTopUp}
              />
              <button
                type='button'
                aria-label='Close top up'
                className='top-up-close icon-close transparent-button'
                onClick={closeTopUp}
              />
            </div>
          ) : (
            <div
              className='robux'
              onClick={() => {
                window.location.href = topUp.buttonUrl;
              }}
              onKeyPress={e => {
                if (e.key === 'Enter' || e.key === 'Space') {
                  window.location.href = topUp.buttonUrl;
                }
              }}
              tabIndex={0}
              role='link'>
              <div className='top-up-background' />
              <div className='top-up-robux' />
              <img src={TopUpChest} className='top-up-chest' alt='' />
              <div className='top-up-body'>
                <h1 className='top-up-header'>{translate(topUp.headerText || '')}</h1>
                <div className='top-up-message'>{translate(topUp.messageText)}</div>
                <a className='top-up-button btn-cta-md' href={topUp.buttonUrl} target='_self'>
                  {translate(topUp.buttonText)}
                </a>
              </div>
              <button
                type='button'
                aria-label='Close top up'
                className='top-up-close-background transparent-button'
                onClick={closeTopUp}
              />
              <button
                type='button'
                aria-label='Close top up'
                className='top-up-close icon-close transparent-button'
                onClick={closeTopUp}
              />
            </div>
          )}
        </div>
      )}

      {/* Results */}
      <div id='results' className='results-container'>
        {/* No Results, or Error Message */}
        {layout.searchItemsError && !layout.loading && (
          <div className='section-content-off'>
            {translate(
              layout.searchItemsError === 'no_results'
                ? 'Response.NoItemsFound'
                : 'Response.TemporarilyUnavailable'
            )}
          </div>
        )}

        {/* Item Cards */}
        {isInfiniteScrollEnabled && (
          <ul className='hlist item-cards-stackable'>
            {orderedItems?.map(item => {
              const shoppingCartProps: ShoppingCartProps = {
                isItemInCart: isItemInCart(item.id),
                addItemToCart,
                removeItemFromCart
              };

              return (
                <ItemCard
                  key={item.id}
                  id={item.id}
                  name={item.name}
                  type={item.itemType}
                  creatorName={item.creatorName}
                  creatorType={item.creatorType}
                  creatorTargetId={item.creatorTargetId}
                  price={item.price}
                  lowestPrice={item.lowestPrice}
                  unitsAvailableForConsumption={item.unitsAvailableForConsumption}
                  itemStatus={(item as TAssetItemDetails).itemStatus}
                  priceStatus={item.priceStatus}
                  premiumPricing={item.premiumPricing?.premiumPriceInRobux}
                  itemRestrictions={item.itemRestrictions}
                  thumbnail2d={
                    <Thumbnail2d
                      type={
                        ItemCardUtils.checkIfBundle(item.itemType)
                          ? ThumbnailTypes.bundleThumbnail
                          : ThumbnailTypes.assetThumbnail
                      }
                      targetId={item.id}
                      size={DefaultThumbnailSize}
                    />
                  }
                  iconToRender={
                    item.creatorHasVerifiedBadge ? (
                      <VerifiedBadgeIconContainer
                        overrideImgClass='verified-badge-icon-catalog-item-rendered'
                        size={BadgeSizes.TITLE}
                        titleText={item.creatorTargetId.toString()}
                      />
                    ) : undefined
                  }
                  shoppingCartProps={shoppingCartProps}
                />
              );
            })}
          </ul>
        )}
        {showNonInfiniteItems && (
          <ul
            className={classNames('hlist item-cards-stackable', {
              faded: layout.loading
            })}>
            {orderedItems?.map(item => {
              const shoppingCartProps: ShoppingCartProps = {
                isItemInCart: isItemInCart(item.id),
                addItemToCart,
                removeItemFromCart
              };

              return (
                <ItemCard
                  key={item.id}
                  id={item.id}
                  name={item.name}
                  type={item.itemType}
                  creatorName={item.creatorName}
                  creatorType={item.creatorType}
                  creatorTargetId={item.creatorTargetId}
                  price={item.price}
                  lowestPrice={item.lowestPrice}
                  unitsAvailableForConsumption={item.unitsAvailableForConsumption}
                  itemStatus={(item as TAssetItemDetails).itemStatus}
                  priceStatus={item.priceStatus}
                  premiumPricing={item.premiumPricing?.premiumPriceInRobux}
                  itemRestrictions={item.itemRestrictions}
                  thumbnail2d={
                    <Thumbnail2d
                      type={
                        ItemCardUtils.checkIfBundle(item.itemType)
                          ? ThumbnailTypes.bundleThumbnail
                          : ThumbnailTypes.assetThumbnail
                      }
                      targetId={item.id}
                      size={DefaultThumbnailSize}
                    />
                  }
                  iconToRender={
                    item.creatorHasVerifiedBadge ? (
                      <VerifiedBadgeIconContainer
                        overrideImgClass='verified-badge-icon-catalog-item-rendered'
                        size={BadgeSizes.TITLE}
                        titleText={item.creatorTargetId.toString()}
                      />
                    ) : undefined
                  }
                  shoppingCartProps={shoppingCartProps}
                />
              );
            })}
          </ul>
        )}

        {/* Spinners and Shimmer Effects */}
        {layout.initialized && layout.loading && <div className='spinner spinner-sm' />}
        {showShimmer && <ShimmerContainer numberOfItemsToDisplay={numberOfItemsToDisplay} />}
      </div>

      {/* Pagination and Infinite Scroll Spinner */}
      {isInfiniteScrollEnabled ? (
        <React.Fragment>
          <div ref={loader} style={{ height: '1px', backgroundColor: 'transparent' }} />
          {!!orderedItems?.length && layout.loading && (
            <div className='spinner spinner-default spinner-infinite-scroll' />
          )}
          {/* Scroll to Top Button */}
          {!!orderedItems?.length && !layout.loading && showReturnToTop && (
            <button
              aria-label='Back to top'
              type='button'
              className='scroll-to-top icon-back-to-top'
              onClick={returnToTop}
            />
          )}
        </React.Fragment>
      ) : (
        <div className='pager-holder' />
      )}
    </div>
  );
}

export default withTranslations(ItemsContainer, translationConfig);
